// ready(function () {

// });

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}
class ColorBlob {
    constructor(containerID, options = {}) {
        this.container = document.getElementById(containerID);
        this.canvas = document.createElement('canvas');
        this.container.appendChild(this.canvas);
        this.ctx = this.canvas.getContext('2d');
        this.canvas.height = this.container.clientHeight;
        this.canvas.width = this.container.clientWidth;
        this.colors = options.colors || ['100,255,100', '255,255,100', '100,255,255', '0,0,0'];
        this.opacity = options.opacity || 0.15;
        this.minSpeed = options.minSpeed || 2;
        this.maxSpeed = options.maxSpeed || 6;
        this.particleCount = options.particleCount || 4;
        this.particleRadius = options.particleRadius || ((this.canvas.height > this.canvas.width) ? this.canvas.height : this.canvas.width);
        this.frameRate = options.frameRate || 1000 / 60;
        this.parts = [];
        this.particles = [];
        this.createParticleTemplates();
    }

    setColors(newcolors) {
        this.colors = newcolors;
        this.parts = [];
        this.createParticleTemplates();
    }

    createParticleTemplates() {
        for (let n = 0; n < this.colors.length; n++) {
            const tmpCanvas = document.createElement('canvas');
            tmpCanvas.width = this.particleRadius * 2;
            tmpCanvas.height = this.particleRadius * 2;
            const tmpCtx = tmpCanvas.getContext('2d');
            const grd = tmpCtx.createRadialGradient(this.particleRadius + (Math.random() / 10), this.particleRadius, this.particleRadius, this.particleRadius, this.particleRadius, this.particleRadius / 1.25);
            grd.addColorStop(0, `rgba(${this.colors[n]}, 0)`);
            grd.addColorStop(1, `rgba(${this.colors[n]}, ${this.opacity})`);
            tmpCtx.fillStyle = grd;
            tmpCtx.fillRect(0, 0, this.particleRadius * 2, this.particleRadius * 2);
            this.parts[n] = tmpCanvas;
        }
    }

    drawParticles() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        for (let n = 0; n < this.particleCount; n++) {
            this.moveParticle(this.particles[n]);
            this.ctx.drawImage(this.particles[n].drawn, this.particles[n].xPos, this.particles[n].yPos);
        }
    // this.ctx.filter = "blur(50px)";
    }

    resetParticle(particle) {
        const size = this.particleRadius;
        const neg = [1, 2];
        const r = this.rand(0, 1);
        if (r > 0.5) neg[0] = -1;
        if (r < 0.75 && r > 0.25) neg[1] = -2;
        particle.xVel = this.rand(this.minSpeed, this.maxSpeed) * neg[0];
        particle.yVel = this.rand(this.minSpeed, this.maxSpeed) * neg[1];
        if (particle.xVel > 0) { // moving left to right
            if (particle.yVel > 0) { // moving top to bottom
                if (particle.xVel > particle.yVel) { // moving more horizontal
                    particle.xPos = 0 - 2 * size;
                    particle.yPos = this.rand(0, this.canvas.height) - size;
                } else { // moving more vertical
                    particle.yPos = 0 - 2 * size;
                    particle.xPos = this.rand(0, this.canvas.width) - size;
                }
            } else { // moving bottom to top
                if (particle.xVel > -particle.yVel) { // moving more horizontal
                    particle.xPos = 0 - 2 * size;
                    particle.yPos = this.rand(0, this.canvas.height) - size;
                } else { // moving more vertical
                    particle.yPos = this.canvas.height;
                    particle.xPos = this.rand(0, this.canvas.width) - size;
                }
            }
        } else { // moving right to left
            if (particle.yVel > 0) { // moving top to bottom
                if (-particle.xVel > particle.yVel) { // moving more horizontal
                    particle.xPos = this.canvas.width;
                    particle.yPos = this.rand(0, this.canvas.height) - size;
                } else { // moving more vertical
                    particle.xPos = this.rand(0, this.canvas.width) - size;
                    particle.yPos = 0 - 2 * size;
                }
            } else { // moving bottom to top
                if (-particle.xVel > -particle.yVel) { // moving more horizontal
                    particle.xPos = this.canvas.width;
                    particle.yPos = this.rand(0, this.canvas.height) - size;
                } else { // moving more vertical
                    particle.yPos = this.canvas.height;
                    particle.xPos = this.rand(0, this.canvas.width) - size;
                }
            }
        }
        this.changeParticleColor(particle);
    }

    distanceBetween(x1, y1, x2, y2) {
        return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
    }

    moveParticle(particle) {
        particle.xPos += particle.xVel;
        particle.yPos += particle.yVel;
        if (particle.xPos > this.canvas.width || particle.yPos > this.canvas.height || particle.xPos < -2 * particle.radius || particle.yPos < -2 * particle.radius) {
            this.resetParticle(particle);
        }
    }

    changeParticleColor(particle) {
        particle.drawn = this.parts[Math.floor(Math.random() * this.parts.length)];
    }

    rand(min, max) {
        return Math.random() * (max - min) + min;
    }

    engage() {
        this.createParticleTemplates();
        for (let n = 0; n < this.particleCount; n++) {
            const neg = [1, 2];
            const r = this.rand(0, 1);
            if (r > 0.5) neg[0] = -1;
            if (r < 0.75 && r > 0.25) neg[1] = -2;
            this.particles[n] = {
                radius: this.particleRadius,
                drawn: this.parts[0],
                xPos: this.rand(0, this.canvas.width) - this.particleRadius,
                yPos: this.rand(0, this.canvas.height) - this.particleRadius,
                xVel: this.rand(this.minSpeed, this.maxSpeed) * neg[0],
                yVel: this.rand(this.minSpeed, this.maxSpeed) * neg[1],
            };
            this.changeParticleColor(this.particles[n]);
        }
        this.requestInterval(this.drawParticles.bind(this), this.frameRate);
        this.drawParticles();
    }

    requestInterval(fn, delay) {
        let requestAnimFrame = (function () {
                return window.requestAnimationFrame || function (callback, element) {
                    window.setTimeout(callback, delay);
                };
            }()),
            start = new Date().getTime(),
            handle = {};
        function loop() {
            handle.value = requestAnimFrame(loop);
            let current = new Date().getTime(),
                delta = current - start;
            if (delta >= delay) {
                fn.call();
                start = new Date().getTime();
            }
        }
        handle.value = requestAnimFrame(loop);
        return handle;
    }

    resize() {
        this.canvas.width = this.container.clientWidth;
        this.canvas.height = this.container.clientHeight;
    }
}
